<template>
  <v-responsive>
    <SubMenuStoreMobile v-if="$vuetify.breakpoint.smAndDown"></SubMenuStoreMobile>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;"> 
      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>
          
          <SubMenuStore></SubMenuStore>
          
              <v-row class="my-0 mx-0" style="background: #fff">
                <v-col>
                     <GlobalSideBannerBottom></GlobalSideBannerBottom>
                </v-col>
              </v-row>


        </v-col>

        <v-col cols="12" sm="12" md="9" :class="$vuetify.breakpoint.smAndDown? 'mt-0 pt-0':''">
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  {{ breadcrumbs[2].text }}
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
          <v-divider></v-divider>
          </v-container>



          <v-row class="my-3 mx-0" no-gutters style="background: #fff">
            <v-col>
                  <GlobalSubBannerTop></GlobalSubBannerTop>
            </v-col>
          </v-row>




          <v-responsive class="mt-10 mb-5 mx-auto" max-width="450" v-if="latestLottoDataAll">

        <h2 class="text-center mb-3">1,2등 최다 판매점</h2>
        <v-select
        class="mt-2 mb-4"
          :items="latestLottoDataAll"
          item-value="drwNo"
          item-text="drwNo"
          v-model="selectDrw"
          return-object

          outlined
          label="회차 선택"
          dense
          hide-details=""
          style="max-width:160px;margin:0 auto;font-weight:bold;"
        ></v-select>



          </v-responsive>



        
          
          <v-responsive class="mt-10">
              <h3>1등 배출점</h3>
              <v-sheet outlined class=" mt-1 " v-if="topStoreRank">
                  <v-simple-table >
                    <template >
                      <thead>
                        <tr>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            번호
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            상호명
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            구분
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            소재지
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            위치보기
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in topStoreRank.top"
                          :key="item.name"
                        >
                          <td class="text-center">{{item.no}}</td>
                          <td class="text-center">{{item.name}}</td>
                          <td class="text-center">{{item.auto}}</td>
                          <td class="text-center">{{item.address}}</td>
                          <td class="text-center">

                            <a v-if="$vuetify.breakpoint.mdAndUp"
                            :href="item.map_pc" target="_blank" style="text-decoration:none;">                              
                              <v-icon>mdi-map-search-outline</v-icon>
                            </a>
                            <a v-else
                            :href="item.map" target="_blank" style="text-decoration:none;">                              
                              <v-icon>mdi-map-search-outline</v-icon>
                            </a>

                          </td>
                          
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
              </v-sheet>
          </v-responsive>
          

          <v-responsive class="mt-10">
              <h3>2등 배출점</h3>
              <v-sheet outlined class=" mt-1 " v-if="topStoreRank">
                  <v-simple-table >
                    <template >
                      <thead>
                        <tr>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            번호
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            상호명
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            소재지
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            위치보기
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in topStoreRank.bottom"
                          :key="item.no"
                        >
                          <td class="text-center">{{item.no}}</td>
                          <td class="text-center">{{item.name}}</td>
                          <td class="text-center">{{item.address}}</td>
                          <td class="text-center">

                            <a v-if="$vuetify.breakpoint.mdAndUp"
                            :href="item.map_pc" target="_blank" style="text-decoration:none;">                              
                              <v-icon>mdi-map-search-outline</v-icon>
                            </a>
                            <a v-else
                            :href="item.map" target="_blank" style="text-decoration:none;">                              
                              <v-icon>mdi-map-search-outline</v-icon>
                            </a>

                          </td>
                          
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
              </v-sheet>
          </v-responsive>




    <v-pagination v-if="false"
    class="mt-10
    "
      v-model="page"
      :length="4"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
    ></v-pagination>



              <v-row class="my-3 mx-0" no-gutters style="background: #fff">
                <v-col>
                     <GlobalSubBannerBottom></GlobalSubBannerBottom>
                </v-col>
              </v-row>

        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
//import HelloWorld from "../components/HelloWorld";

export default {
  name: "Store",
  components: {
    //HelloWorld,
  },
  data: () => ({
    breadcrumbs: [
      { text: "홈" },
      { text: "판매점 분석" },
      { text: "1,2등 최다 판매점" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],


        page: 1,
    
        freeboard: [
          {
            idx: 975,
            numbers: '1613373840+9',
            grade: '5',
          },
          {
            idx: 975,
            numbers: '1613373840+9',
            grade: '5',
          },
          {
            idx: 975,
            numbers: '1613373840+9',
            grade: '5',
          },
          {
            idx: 975,
            numbers: '1613373840+9',
            grade: '5',
          },
          {
            idx: 975,
            numbers: '1613373840+9',
            grade: '5',
          },
        ],



latestLottoDataAll:null,
selectDrw:null,

topStoreRank:null,

  }),


  mounted() {
    //페이지 시작하면은 자동 함수 실행
    this.getLatestResult();
  },

  watch: {
    selectDrw() {
      console.log(this.selectDrw.drwNo);
      this.getTopStoreRank(this.selectDrw.drwNo);
    },
  },

  methods: {

    getTopStoreRank(drwNo) {

      this.$axios
        .get(
          "//lotto7777.whynot.so/lottoapp/gettopstorerank_drwNo.php?drwNo="+drwNo
        )
        .then((res) => {
          console.log(res);

          this.topStoreRank = res.data;

/*
          this.lottoNumbers = [
            this.latestLottoData['drwtNo1'],
            this.latestLottoData['drwtNo2'],
            this.latestLottoData['drwtNo3'],
            this.latestLottoData['drwtNo4'],
            this.latestLottoData['drwtNo5'],
            this.latestLottoData['drwtNo6']
            ];

          this.lottoBonus = this.latestLottoData['bnusNo'];
*/


        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });

    },



    getLatestResult() {

      this.$axios
        .get(
          "//lotto7777.iwinv.net/lottoapp/?drwNo=all&mode=get&order=desc"
        )
        .then((res) => {
          console.log(res);

          this.latestLottoDataAll = res.data;
          this.selectDrw = this.latestLottoDataAll[0];

/*
          this.lottoNumbers = [
            this.latestLottoData['drwtNo1'],
            this.latestLottoData['drwtNo2'],
            this.latestLottoData['drwtNo3'],
            this.latestLottoData['drwtNo4'],
            this.latestLottoData['drwtNo5'],
            this.latestLottoData['drwtNo6']
            ];

          this.lottoBonus = this.latestLottoData['bnusNo'];
*/


        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });

    },


  }
};
</script>
<style></style>
